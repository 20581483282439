import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import HeroComp from "../sharedLandingcomponents/Hero_Comp";
import MissionComp from "../sharedLandingcomponents/Mission_Comp";
import CoreValueComp from "../sharedLandingcomponents/Core_Value_Comp";
import FaqComp from "../sharedLandingcomponents/Faq_Comp";
import ContactComp from "../sharedLandingcomponents/Contact_Comp";
import ServiceSlide from "../sharedLandingcomponents/Service_Slide_Comp";
import TrustedBrandComp from "../sharedLandingcomponents/brand_info";
import { TestimonyComp } from "../sharedLandingcomponents/Testimony_Comp";
import CounterComp from "../sharedLandingcomponents/Counter_Comp";
import BlogComp from "../sharedLandingcomponents/Blog_Comp";
import WhyChooseUsComp from "../sharedLandingcomponents/WhyChooseUs";
import ClientExperienceComp from "../sharedLandingcomponents/Client_Experience";

export const HomePage = () => {
  return (
    <div className="bg-blue-950 min-h-screen">
      {/* Hero Section */}
      <div>
        {/* <HeroSlideComp /> */}
        <HeroComp />
      </div>
      {/* End Hero Section */}

      {/* Core Value */}
      <section>
        <CoreValueComp />
      </section>
      {/* Core Value */}

      {/* Our Service */}
      <section>
        <ServiceSlide />
      </section>
      {/* Our Service */}

      <ClientExperienceComp></ClientExperienceComp>

      <WhyChooseUsComp></WhyChooseUsComp>

      {/* Mission Statement */}
      <section>
        <MissionComp />
      </section>
      {/* Mission Statement */}

      {/* Counter */}
      <section>
        <CounterComp />
      </section>
      {/* Counter */}

      {/* Blog Comp */}
      {/* <section>
        <BlogComp />
      </section> */}
      {/* Blog Comp */}

      {/* Testimony */}
      {/* <section>
        <TestimonyComp />
      </section> */}
      {/* Testimony */}

      {/* FAQ */}
      <section>
        <FaqComp />
      </section>
      {/* FAQ */}

      {/* Trusted Brands */}
      <section>
        <TrustedBrandComp />
      </section>
      {/* Trusted Brands */}

      {/* Contact Us */}
      <section>
        <ContactComp />
      </section>
      {/* Contact Us */}
    </div>
  );
};

export default HomePage;
