import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import Slide from "react-reveal/Slide";

export const MissionComp = () => {
  return (
    <>
      {/* Mission Statement */}
      <div className="bg-cover bg-center w-full text-center py-12 text-white fontJostRegular">
        <div className="container mx-auto px-4 py-20">
          <Slide up>
            <div>
              <h2 className="font-bold fontJostBold text-4xl">
                Mission Statement
              </h2>
            </div>

            <p className="mt-6 text-base">
              Clearwater Communications in Dallas isn't just about delivering
              digital services; it's about building digital ecosystems where
              businesses can thrive. Whether you're looking to establish an
              online presence, revamp your digital strategy, or enhance your
              brand's digital footprint, Clearwater Communications offers the
              expertise, agility, and commitment needed to lead you to success
              in the digital era.
            </p>

            <p className="mt-6 text-base">
              Their expanded services in influencer marketing, online reputation
              management, and public relations ensure that your brand's digital
              journey is comprehensive, covering all aspects necessary for
              modern market success.
            </p>
          </Slide>
        </div>
      </div>
      {/* End Mission Statement */}
    </>
  );
};

export default MissionComp;
