import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import PagerHeaderComp from "../sharedLandingcomponents/PagesHeaderComp";
import JobsCard from "../sharedLandingcomponents/Jobs_Card_Comp";

const OurJobPage = () => {
  return (
    <div className="bg-blue-950 min-h-[400px]">
      <PagerHeaderComp title="Our Portfolios" />
      <div className="container bg-blue-950 mx-auto px-4 py-8">
        <JobsCard></JobsCard>
      </div>
    </div>
  );
};

export default OurJobPage;
