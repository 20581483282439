import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import MotionCompGeneral from "./Motion_Comp";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";
import {
  PieChartOutlined,
  ClockCircleOutlined,
  ProjectOutlined,
} from "@ant-design/icons";

export const CoreValueComp = () => {
  const coreValue: IList[] = [
    {
      title: "Affordable Pricing:",
      caption:
        "Understanding the financial constraints of growing businesses, Clearwater provides cost-effective solutions without compromising on quality. Their pricing model is transparent, allowing clients to see where every dollar is invested.",
      icon: <PieChartOutlined style={{ fontSize: "24px", color: "#FFD700" }} />,
    },
    {
      title: "Quick Turnaround",
      caption:
        " In the fast-paced digital world, time is of the essence. Clearwater Communications prides itself on its ability to deliver projects swiftly, ensuring businesses can launch or update their digital assets promptly.",
      icon: (
        <ClockCircleOutlined style={{ fontSize: "24px", color: "#FF6347" }} />
      ),
    },
    {
      title: "Multi-Project Management",
      caption:
        " With the capability to handle numerous projects simultaneously, Clearwater can serve as a one-stop shop for various digital needs, from small startups to large enterprises, ensuring consistent quality across all engagements.",
      icon: <ProjectOutlined style={{ fontSize: "24px", color: "#1E90FF" }} />,
    },
  ];

  return (
    <>
      {/* Core Value */}
      <div className="bg-blue-800 text-white py-2 pb-8">
        {/* Motions for Traffic */}
        <div className="hidden md:block">
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
            showRotateRight={true}
            rotateRightImg={`${
              process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
            }`}
          />
        </div>
        <div className="container mx-auto px-4 fontJostRegular">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 md:w-6/12 flex flex-col">
              <div className={"mt-12 px-4"}>
                <Slide up>
                  <h2 className="text-left text-5xl fontJostRegular font-semibold mb-8">
                    <span className="text-sky-200">Company </span>Philosophy
                    <br />
                  </h2>
                  <p className="mt-4 text-lg text-left">
                    Clearwater Communications operates under the belief that
                    every business, regardless of size, deserves access to
                    top-tier digital solutions. Their approach combines
                    creativity with analytical prowess, focusing on measurable
                    outcomes rather than just service delivery.
                  </p>
                  <p className="mt-4 text-base text-left">
                    The company culture is built around client partnership,
                    where understanding the business goals and aligning digital
                    strategies to meet these objectives is paramount.
                  </p>

                  <p className="mt-20 mb-10 text-left">
                    <Link
                      className="btn bg-sky-950 px-8 py-5 text-white font-semibold rounded-3xl shadow-lg mb-4 mr-4"
                      to={"/landing/about"}
                    >
                      <span> Read More </span>
                    </Link>
                  </p>
                </Slide>
              </div>
            </div>
            <div className="w-full lg:w-6/12 md:w-6/12 flex">
              <div className="grid grid-cols-1 gap-2 justify-center mt-8">
                {coreValue.map((item: IList, index: number) => (
                  <Slide up key={index}>
                    <div className="p-4 border rounded-lg flex items-start">
                      <div className="mr-4">{item.icon}</div>
                      <div>
                        <h4 className="font-bold text-xl mt-0 fontJostBold">
                          {item.title}
                        </h4>
                        <p className="mt-2 text-base">{item.caption}</p>
                      </div>
                    </div>
                  </Slide>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Core Value */}
    </>
  );
};

export default CoreValueComp;
