import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
import { CheckCircleOutlined } from "@ant-design/icons";

export const WhyChooseUsComp = () => {
  const points = [
    {
      title: "Expertise and Innovation",
      description:
        "With a team of seasoned developers, marketers, and strategists, Clearwater stays ahead of digital trends, employing the latest technologies and marketing techniques.",
    },
    {
      title: "Custom Solutions",
      description:
        "Unlike one-size-fits-all agencies, Clearwater customizes each project to fit the unique needs of the client, ensuring that digital solutions align with business strategies.",
    },
    {
      title: "Proven Track Record",
      description:
        "Clearwater's portfolio showcases a variety of successful projects across different industries, demonstrating their versatility and effectiveness in digital transformation.",
    },
    {
      title: "Community and Growth",
      description:
        "By choosing Clearwater, businesses not only enhance their digital footprint but also become part of a community focused on growth, learning, and innovation in the digital space.",
    },
  ];

  return (
    <>
      {/* Hero Section */}
      <div className="pt-20 w-full bg-sky-950 text-white">
        <div className="container mx-auto px-6 py-3 fontJostRegular">
          <div className="flex flex-wrap">
            {/* Image Section */}
            <div className="w-full lg:w-5/12 md:w-5/12 flex items-center justify-center">
              <Slide up>
                <img
                  className="max-w-full w-4/5 rounded-lg"
                  alt="heroImage"
                  src={`${
                    process.env.PUBLIC_URL + "/images/home/whychooseus.jpeg"
                  }`}
                />
              </Slide>
            </div>

            {/* Text Section */}
            <div className="w-full lg:w-7/12 md:w-7/12 p-4">
              <Slide left>
                <h2 className="text-3xl lg:text-5xl font-bold leading-tight fontJostBold">
                  Why Choose Clearwater Communications?
                </h2>
                <div className="mt-12 space-y-8">
                  {points.map((point, index) => (
                    <div key={index} className="flex items-start space-x-4">
                      <Slide up>
                        <CheckCircleOutlined
                          className="text-green-400 text-2xl flex-shrink-0"
                          aria-hidden="true"
                        />
                        <div>
                          <h3 className="text-xl font-semibold">
                            {point.title}
                          </h3>
                          <p className="text-base mt-2">{point.description}</p>
                        </div>
                      </Slide>
                    </div>
                  ))}
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
      {/* End Hero Section */}
    </>
  );
};

export default WhyChooseUsComp;
