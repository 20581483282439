import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleLinkClick = () => {
    setShowMenu(false);
  };

  return (
    <>
      <nav className="bg-blue-950 text-white shadow-lg fixed w-full z-10 pt-4 pb-4">
        <div className="container mx-auto px-6 py-3 flex justify-between items-center fontJostRegular">
          <div className="flex items-center">
            <img
              src={`${process.env.PUBLIC_URL + "/images/logob.png"}`}
              alt="Logo"
              className="h-12 w-48 mr-3 rounded"
            />
            <div className="hidden md:flex space-x-6 ml-6">
              <Link
                to="/home"
                className="text-white hover:text-gray-600  text-base font-medium"
              >
                Home
              </Link>
              <Link
                to="/about"
                className="text-white hover:text-gray-600  text-base font-medium"
              >
                About
              </Link>
              <Link
                to="/services"
                className="text-white hover:text-gray-600  text-base font-medium"
              >
                Service
              </Link>
              <Link
                to="/ourjobs"
                className="text-white hover:text-gray-600  text-base font-medium"
              >
                Our Portfolios
              </Link>
              <Link
                to="/#_"
                className="text-white hover:text-gray-600  text-base font-medium"
              >
                Blog
              </Link>
              <Link
                to="/contact"
                className="text-white hover:text-gray-600  text-base font-medium"
              >
                Contact
              </Link>
            </div>
          </div>
          <div className="hidden md:flex">
            <Link
              to="/contact"
              className="bg-sky-950 rounded-2xl text-white px-4 py-4 hover:bg-gray-800  text-base font-medium"
            >
              Contact Us
            </Link>
          </div>
          <div className="md:hidden flex items-center">
            <button
              className="outline-none mobile-menu-button"
              onClick={() => setShowMenu(!showMenu)}
            >
              <svg
                className="w-6 h-6 text-gray-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d={
                    showMenu ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"
                  }
                />
              </svg>
            </button>
          </div>
        </div>
        {showMenu && (
          <div className="mobile-menu">
            <ul className="">
              <li>
                <Link
                  to="/home"
                  className="block px-2 py-4 text-white hover:text-gray-600  text-base font-medium"
                  onClick={handleLinkClick}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="block px-2 py-4 text-white hover:text-gray-600  text-base font-medium"
                  onClick={handleLinkClick}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="block px-2 py-4 text-white hover:text-gray-600  text-base font-medium"
                  onClick={handleLinkClick}
                >
                  Service
                </Link>
              </li>
              <li>
                <Link
                  to="/ourjobs"
                  className="block px-2 py-4 text-white hover:text-gray-600  text-base font-medium"
                  onClick={handleLinkClick}
                >
                  Our Portfolios
                </Link>
              </li>
              <li>
                <Link
                  to="/#_"
                  className="block px-2 py-4 text-white hover:text-gray-600  text-base font-medium"
                  onClick={handleLinkClick}
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="block px-2 py-4 text-white hover:text-gray-600  text-base font-medium"
                  onClick={handleLinkClick}
                >
                  Contact
                </Link>
              </li>
              {/* <li>
                <a
                  href="#_"
                  className="block px-2 py-4 text-white hover:text-gray-600  text-base font-medium"
                  onClick={handleLinkClick}
                >
                  Download
                </a>
              </li> */}
            </ul>
          </div>
        )}
      </nav>

      <div style={{ height: "80px" }}>
        <br />
      </div>
    </>
  );
};

export default Navbar;
