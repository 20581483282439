import { IList } from "../../../../utils/generalType";
import ServiceCyberSecurityCard from "./Service_Cyber";
import { ServiceExecutiveBrandingCard } from "./Service_Executives_Branding";
import ServiceInfluencerMarketingCard from "./Service_Influencer_Marketing";
import ServiceMobileAppCard from "./Service_Mobile";
import ServicePRCard from "./Service_PR";
import ServiceSEOCard from "./Service_SEO";
import ServiceSMACard from "./Service_SMA";
import ServiceSocialFootPrintCard from "./Service_Social_Foot_Print";
import ServiceTwitterTrendCard from "./Service_Twitter_Trend";
import ServiceWebDesignCard from "./Service_Web";

export const services: IList[] = [
  {
    title: "Public Relations",
    subTitle: "Elevate Your Brand with Reputation & Brand Boosting",
    caption:
      " Beyond digital, Clearwater extends its expertise into crafting press releases, media outreach, crisis communication, and brand storytelling. This holistic approach ensures a consistent and positive brand image across all channels, both online and offline.",
    image: `${process.env.PUBLIC_URL + "/images/services/service_market.jpg"}`,
    content: <ServicePRCard />,
    link: "/landing/services/1/Public_Relations_Management",
  },
  {
    title: "Search Engine Optimization",
    subTitle: "Boost Your Online Presence with Advanced SEO Techniques",
    caption:
      " Clearwater excels in driving organic traffic through strategic SEO practices. They employ both on-page and off-page optimization techniques to ensure clients rank highly on search engine results pages.",
    image: `${
      process.env.PUBLIC_URL + "/images/services/service_strategy.jpg"
    }`,
    content: <ServiceSEOCard />,
    link: "/landing/services/2/SEO",
  },
  {
    title: "Social Media Advocacy",
    subTitle: "Harness the Power of Social Media to Amplify Your Brand",
    caption:
      "Leveraging the power of social platforms, Clearwater Communications crafts bespoke social media strategies that boost brand visibility, engage with audiences, and drive conversions. Their approach is data-driven, ensuring campaigns resonate with the target demographic.",
    image: `${
      process.env.PUBLIC_URL +
      "/images/services/service_Social_media_advocacy.jpg"
    }`,
    content: <ServiceSMACard />,
    link: "/landing/services/3/social_Media_Advocacy",
  },
  {
    title: "Adaptive Web Design",
    subTitle: "Transforming Your Vision into a Stunning Online Presence",
    caption:
      "Crafting responsive, user-friendly websites that are not only aesthetically pleasing but also optimized for performance and SEO. Clearwater Communications ensures that each site is built with scalability in mind, allowing businesses to grow their online presence effortlessly.",
    image: `${process.env.PUBLIC_URL + "/images/services/service_web.jpg"}`,
    content: <ServiceWebDesignCard />,
    link: "/landing/services/4/Website_Design",
  },
  {
    title: "Mobile Apps Development",
    subTitle: "Transforming Ideas into Intuitive, High-Performance Mobile Apps",
    caption:
      "Specializing in developing mobile apps for both Android and iOS platforms, Clearwater Communications delivers applications that enhance user engagement, offer seamless functionality, and provide an intuitive user interface. Their apps are designed to meet the high standards of app stores, focusing on user retention and satisfaction.",
    image: `${process.env.PUBLIC_URL + "/images/services/service_mobile.jpg"}`,
    content: <ServiceMobileAppCard />,
    link: "/landing/services/5/MobileApp_Development",
  },
  {
    title: "Cyber Security",
    subTitle: "Protect Your Business with Advanced Cyber Security Solutions",
    caption:
      "Safeguard your business with cutting-edge cyber security strategies tailored to your needs. Let’s protect your data and assets.",
    image: `${
      process.env.PUBLIC_URL + "/images/services/service_cyber_security.jpg"
    }`,
    content: <ServiceCyberSecurityCard />,
    link: "/landing/services/6/Cyber_Security",
  },
  {
    title: "Social Media Footprint Expansion",
    subTitle: "Broaden Your Online Reach with Strategic Social Media Growth",
    caption:
      "Expand your digital footprint and connect with new audiences through targeted social media growth strategies.",
    image: `${
      process.env.PUBLIC_URL +
      "/images/services/service_social_media_expansion.jpg"
    }`,
    content: <ServiceSocialFootPrintCard />,
    link: "/landing/services/7/Social_Media_Foot_Print_Expansion",
  },
  {
    title: "Twitter Trend Takeover",
    subTitle:
      "Dominate the Twitter Conversation with Our Trend Takeover Service",
    caption:
      "Create buzz on Twitter and become a top trend with our specialized trend takeover service. Let’s get you trending!",
    image: `${
      process.env.PUBLIC_URL + "/images/services/service_twitter_trend.jpg"
    }`,
    content: <ServiceTwitterTrendCard />,
    link: "/landing/services/8/Twitter_Trend_Take_Over",
  },
  {
    title: "Influencer Marketing",
    subTitle: "Leverage Influencers to Amplify Your Brand's Reach",
    caption:
      "Harnessing the power of influencers, Clearwater connects brands with personalities that align with their values and target market. This service includes strategy development, influencer selection, campaign execution, and performance analysis to amplify brand messages through trusted voices.",
    image: `${
      process.env.PUBLIC_URL +
      "/images/services/service_influencer_marketing.jpg"
    }`,
    content: <ServiceInfluencerMarketingCard />,
    link: "/landing/services/9/Influencer_Marketing",
  },
  {
    title: "Executive Branding",
    subTitle:
      "Build a Powerful Personal Brand with Our Executive Branding Service",
    caption:
      "Strengthen your personal brand and enhance your leadership profile with strategic executive branding services.",
    image: `${
      process.env.PUBLIC_URL + "/images/services/service_executive_branding.jpg"
    }`,
    content: <ServiceExecutiveBrandingCard />,
    link: "/landing/services/10/Executives_Branding",
  },
];

export const jobs: IList[] = [
  {
    title: "Linkmie",
    subTitle: "Linkmie",
    caption:
      "Linkmie is a dynamic P2P advertisement platform designed to connect publishers and advestisers seamlessly. ",
    image: `${process.env.PUBLIC_URL + "/images/jobs/1.png"}`,
    content: <></>,
    link: "https://linkmie.com",
  },
  {
    title: "Idashyou",
    subTitle: "Idashyou.ng",
    caption:
      "iDashu is a compassionate crowdfunding platform designed to empower individuals and communities to raise funds for causes that matter.",
    image: `${process.env.PUBLIC_URL + "/images/jobs/2.png"}`,
    content: <></>,
    link: "https://idashyou.ng/",
  },
  {
    title: "Jamb CBT Learning App",
    subTitle: "Jamb CBT Learning App",
    caption:
      "Master Your Exams with Ease – Jamb CBT Learning App: Your Ultimate Study Companion!",
    image: `${process.env.PUBLIC_URL + "/images/jobs/3.png"}`,
    content: <></>,
    link: "https://play.google.com/store/apps/details?id=com.lfxedu.jambproject",
  },
  {
    title: "LampHills Website",
    subTitle: "LampHills Website",
    caption:
      "LampHills: Your Trusted Partner for Strategic PR Solutions – Elevate Your Brand, Amplify Your Voice",
    image: `${process.env.PUBLIC_URL + "/images/jobs/4.png"}`,
    content: <></>,
    link: "https://lamphills.com/",
  },
  {
    title: "Edu learn App",
    subTitle: "Edu Learn App",
    caption:
      "Edu Learn App: Empowering K-12 Students with Engaging and Effective Learning Tools!",
    image: `${process.env.PUBLIC_URL + "/images/jobs/5.png"}`,
    content: <></>,
    link: "https://apps.apple.com/gb/app/edu-learn-app/id6670703012?uo=2",
  },
  {
    title: "Soft Life App",
    subTitle: "Soft Life App",
    caption:
      "Discover the ultimate hair care experience with the Soft Life App. Book appointments, explore styles, and manage your hair wellness effortlessly. Your journey to perfect hair starts here.",
    image: `${process.env.PUBLIC_URL + "/images/jobs/6.png"}`,
    content: <></>,
    link: "https://softlifeng.com/",
  },
];
