import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

export const ServiceCyberSecurityCard = () => {
  return (
    <>
      <Slide up>
        <p className="w3-center">
          <img
            width="70%"
            src={`${
              process.env.PUBLIC_URL +
              "/images/services/service_cyber_security.jpg"
            }`}
            alt="cyber_security_image"
          />
        </p>
      </Slide>
      <Slide left>
        <p className="fontJostRegular w3-medium">
          <br />
          In today's digital landscape, safeguarding your business from cyber
          threats is critical. Our advanced Cyber Security solutions protect
          your data, infrastructure, and brand reputation from evolving threats.
        </p>
        <p className="fontJostRegular w3-medium">
          At ClearWater, we employ cutting-edge security protocols, threat
          monitoring, and proactive defense strategies to ensure your business
          stays secure in an increasingly complex digital world.
        </p>
        <p className="fontJostRegular w3-medium">
          From vulnerability assessments and penetration testing to
          round-the-clock monitoring and rapid incident response, our team of
          cybersecurity experts is committed to keeping your operations secure.
        </p>
        <p className="fontJostRegular w3-medium">
          <br /> <br />
          <span className="text-3xl">
            Our comprehensive approach to cybersecurity includes:
          </span>
          <br /> <br />
          <ul>
            <li className="bg-blue-200 p-4 rounded text-gray-950 mb-2">
              Real-time Threat Monitoring & Detection.
            </li>
            <li className="bg-blue-200 p-4 rounded text-gray-950 mb-2">
              Advanced Encryption & Secure Data Management.
            </li>
            <li className="bg-blue-200 p-4 rounded text-gray-950 mb-2">
              Incident Response & Disaster Recovery Planning.
            </li>
            <li className="bg-blue-200 p-4 rounded text-gray-950 mb-2">
              Employee Cybersecurity Training & Awareness.
            </li>
          </ul>
        </p>
        <p className="fontJostRegular w3-medium">
          <br />
          ClearWater’s cybersecurity solutions ensure that your business remains
          resilient, allowing you to focus on growth while we handle the
          complexities of cyber defense.
        </p>

        {/* Call to Action Section */}
        <p
          className="bg-sky-950 text-white callToAction w3-round-large w3-center"
          style={{ marginTop: "50px" }}
        >
          <p className="w3-large fontJostBold w3-padding">
            Protect your business from cyber threats today. Let ClearWater’s
            Cyber Security services provide the shield your business needs to
            operate with confidence.
          </p>{" "}
          <br />
          <Link
            className="btn bg-white px-8 py-5 font-semibold rounded-3xl shadow-lg text-sky-950 mt-8 mb-4"
            to={"/contact"}
          >
            <span> Get a Quote</span>
          </Link>
        </p>
      </Slide>
    </>
  );
};

export default ServiceCyberSecurityCard;
